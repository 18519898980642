import React from 'react'

interface ZoomOutProps {
  color: string
}

export const ZoomOut = ({ color }: ZoomOutProps) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2921 6.5207C10.0546 6.5207 6.61934 9.95592 6.61934 14.1935C6.61934 18.431 10.0546 21.8663 14.2921 21.8663C18.5297 21.8663 21.9649 18.431 21.9649 14.1935C21.9649 9.95592 18.5297 6.5207 14.2921 6.5207ZM5.31934 14.1935C5.31934 9.23795 9.33659 5.2207 14.2921 5.2207C19.2476 5.2207 23.2649 9.23795 23.2649 14.1935C23.2649 16.4876 22.404 18.5806 20.9875 20.1671L24.4897 23.6693C24.7436 23.9231 24.7436 24.3347 24.4897 24.5885C24.2359 24.8424 23.8244 24.8424 23.5705 24.5885L20.054 21.0721C18.4952 22.3792 16.4856 23.1663 14.2921 23.1663C9.33659 23.1663 5.31934 19.149 5.31934 14.1935ZM9.84967 14.1873C9.84967 13.8283 10.1407 13.5373 10.4997 13.5373H17.9997C18.3587 13.5373 18.6497 13.8283 18.6497 14.1873C18.6497 14.5463 18.3587 14.8373 17.9997 14.8373H10.4997C10.1407 14.8373 9.84967 14.5463 9.84967 14.1873Z"
        fill={color || '#2A0062'}
      />
    </svg>
  )
}
