import { axiosInstance } from './axiosConfig'
import { devLog, isLocalDev } from './devLog'

export const keepAlive = () => {
  if (isLocalDev()) return

  if (!window.location.pathname.includes('error')) {
    try {
      const apiUrl = process.env.WEB_API_URL

      axiosInstance.post(
        `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/heartbeat`,
        {},
        {
          withCredentials: true,
        }
      )
    } catch (e) {
      devLog(e)
    }
  }
}
