import styled from 'styled-components'

interface FullscreenWrapProps {
  bgColor?: string
  zIndex?: string
}

interface ContainerProps {
  width?: string
  height?: string
  margin?: string
  color?: string
  bgColor?: string
  border?: string
  borderRadius?: string
  mobileWidth?: string
  mobileHeight?: string
  mobileMargin?: string
  mobilePadding?: string
  fontSize?: string
}

interface CloseProps {
  top?: string
  right?: string
  left?: string
  bottom?: string
  cursor?: string
}

interface HeaderProps {
  color?: string
  bgColor?: string
  border?: string
  fontSize?: string
  borderRadius?: string
}

interface BodyProps {
  padding?: string
}

interface FooterProps {
  border?: string
}

export const FullscreenWrap = styled.div.attrs({
  className: 'authflow-popup-wrap',
})<FullscreenWrapProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'rgba(0, 0, 0, 0.7)'};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${(props) => (props.zIndex ? props.zIndex : '1')};
  backdrop-filter: blur(8px);

  @media (max-width: 980px) {
    height: 100vh !important;
    position: fixed !important;
  }
`

export const Container = styled.div.attrs({
  className: 'authflow-popup',
})<ContainerProps>`
  width: ${(props) => (props.width ? props.width : '800px')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  margin: ${(props) => (props.margin ? props.margin : '30px auto')};
  color: ${(props) => (props.color ? props.color : '#000')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#FFFFFF')};
  border: ${(props) => (props.border ? props.border : undefined)};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  font-style: normal;
  font-weight: 400;
  box-shadow: 0 15px 30px 0 rgba(#000, 0.25);

  @media (max-width: 980px) {
    width: ${(props) => (props.mobileWidth ? props.mobileWidth : '100%')};
    margin: ${(props) => (props.mobileMargin ? props.mobileMargin : '0')};
    height: ${(props) => (props.mobileHeight ? props.mobileHeight : '100%')};

    ${(props) => props.mobilePadding && `padding: ${props.mobilePadding};`}
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
`

export const Close = styled.div.attrs({
  className: 'authflow-popup-close',
})<CloseProps>`
  position: absolute;
  top: ${(props) => (props.top ? props.top : '10px')};
  right: ${(props) => (props.right ? props.right : '10px')};
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};

  ${(props) => props.left && `left: ${props.left};`}
  ${(props) => props.bottom && `bottom: ${props.bottom};`}
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
`

export const PopupHeader = styled.header.attrs({
  className: 'authflow-popup-header',
})<HeaderProps>`
  color: ${(props) => (props.color ? props.color : '#000')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#FFFFFF')};
  padding: 12px 30px;
  border-radius: ${(props) =>
    props.borderRadius
      ? `${props.borderRadius} ${props.borderRadius} 0 0`
      : '8px 8px 0 0'};
  border-bottom: ${(props) => (props.border ? props.border : '1px solid #ddd')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PopupTitle = styled.h1.attrs({
  className: 'authflow-popup-title',
})`
  display: flex;
  align-items: center;
  font-size: 2em;
  font-style: normal;
  font-weight: 400;
`

export const PopupBody = styled.section.attrs({
  className: 'authflow-popup-body',
})<BodyProps>`
  padding: ${(props) => (props.padding ? props.padding : '30px')};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  h1 {
    font-size: 1.5rem;
    line-height: 1.125;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1.25;
  }

  h3 {
    font-size: 1rem;
    line-height: 1.5;
  }

  & > * + * {
    margin-top: 1em;
  }

  & > * + :is(h1, h2, h3) {
    margin-top: 2em;
  }

  & > :is(h1, h2, h3) + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    margin-left: 20px;
    list-style-position: inside;
  }

  ol {
    list-style: numeric;
  }

  ul {
    list-style: disc;
  }
`

export const PopupFooter = styled.footer.attrs({
  className: 'authflow-popup-footer',
})<FooterProps>`
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: ${(props) => (props.border ? props.border : '1px solid #ddd')};
  gap: 12px;
  position: relative;
`
