import React, { FC, ReactNode, useContext, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import {
  FilledBar,
  Container,
  ProgressBar,
  ProgressContainer,
  ProgressIndex,
  ProgressTitle,
  ZoomControlsWrappper,
  SingleBar,
  ProgressBarsContainer,
  ProgressTitleHeader,
  ProgressTitleDocumentName,
} from './SignProgress.styles'
import { StoreContext } from '../App'
import { Document } from '@/components/SignDocument/types'

interface SignProgressProps {
  children?: ReactNode
  currentPage: number
  documentList: Document[]
  setContainerHeight?: (value: number) => void
}

export const SignProgress: FC<SignProgressProps> = observer(
  ({ children, currentPage, documentList, setContainerHeight }) => {
    const store = useContext(StoreContext)
    const { signDocumentPage: trans } = store.TranslationsState.translations
    const { theme } = store.InterfaceState

    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (containerRef.current && documentList?.length) {
        setContainerHeight(containerRef?.current?.clientHeight + 10)
      }
    }, [containerRef.current, documentList])

    const docName = documentList?.at(currentPage - 1)?.name
    return (
      <Container ref={containerRef}>
        <ProgressContainer>
          <ProgressIndex
            fontSize={theme.button.fontSize}
            color={theme.header.color}
          >
            {trans.signProgress.document} {currentPage} {trans.signProgress.of}{' '}
            {documentList?.length}
          </ProgressIndex>

          {documentList?.length <= 5 ? (
            <ProgressBarsContainer>
              {documentList.map((doc, index) => {
                return (
                  <SingleBar
                    key={doc.fileId}
                    active={index + 1 <= currentPage}
                    primaryColor={theme.globals.linkColor}
                  />
                )
              })}
            </ProgressBarsContainer>
          ) : (
            <ProgressBar>
              <FilledBar
                color={theme.globals.linkColor}
                progress={currentPage / documentList?.length}
              />
            </ProgressBar>
          )}

          <ProgressTitle {...theme.header}>
            <ProgressTitleHeader width={containerRef.current?.clientWidth}>
              {trans.header}
              {!!docName && <span>:</span>}
            </ProgressTitleHeader>

            <ProgressTitleDocumentName
              width={containerRef.current?.clientWidth}
              isVisible={!!docName}
            >
              {docName}
            </ProgressTitleDocumentName>
          </ProgressTitle>
        </ProgressContainer>

        <ZoomControlsWrappper>{children}</ZoomControlsWrappper>
      </Container>
    )
  }
)
