import React, { FC, useEffect, useState } from 'react'
import { PageNumberIndicator } from './PdfViewer.styles'

interface IndicatorProps {
  currentPage: number
  totalPages: number
  shouldShow: boolean
  setShouldShow: (value: boolean) => void
}

export const Indicator: FC<IndicatorProps> = ({
  currentPage,
  totalPages,
  shouldShow,
  setShouldShow,
}) => {
  const [isPageIndicatorVisible, setIsPageIndicatorVisible] = useState(false)
  const [pageIndicatorTimeout, setPageIndicatorTimeout] =
    useState<NodeJS.Timeout>()

  const handlePageIndicatorVisibility = () => {
    setShouldShow(false)
    setIsPageIndicatorVisible(true)
    //kill timeout
    if (pageIndicatorTimeout) {
      clearTimeout(pageIndicatorTimeout)
    }
    setIsPageIndicatorVisible(true)

    setPageIndicatorTimeout(
      setTimeout(() => {
        setIsPageIndicatorVisible(false)
      }, 2000)
    )
  }

  useEffect(() => {
    if (shouldShow) {
      handlePageIndicatorVisibility()
    }
  }, [shouldShow])

  return (
    <>
      {isPageIndicatorVisible && (
        <PageNumberIndicator>
          {currentPage} / {totalPages}
        </PageNumberIndicator>
      )}
    </>
  )
}
