import {
  Button,
  ContentContainer,
  DocumentCheckbox,
  Header,
  PdfViewer,
  Popup,
} from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import PrivacyPolicy from '../../assets/privacy_policy.pdf'
import PrivacyPolicyDe from '../../assets/privacy_policy_de.pdf'
import { StoreContext } from '../../components/App'
import { actionReport } from '../../methods/actionReport'
import { getChecksumSha256FromFile } from '../../methods/checksumSha256'
import { devLog } from '../../methods/devLog'
import {
  Divider,
  DtpGeneralConditionsSpacerMobile,
} from '../../styles/dtp.styles'
import {
  ButtonText,
  MicropaymentInfoContent,
  MicropaymentInfoGraph,
} from '../../styles/generic.styles'
import { WelcomeCheck } from '../../styles/jitpay.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
} from '../../styles/layout.styles'
import { DTPInfoGraph } from './DTPInfoGraph'

export const DTPInfoPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth, pageHeight } = store.AppState

  const { context, currentPageIndex } = store.ScenarioState
  const { theme } = store.InterfaceState
  const { language } = store.TranslationsState
  const { DTPInfoPage: trans } = store.TranslationsState.translations

  const [idData, setIdData] = useState(false)
  const [signed, setSigned] = useState(false)
  const [policyPopupVisible, setPolicyPopupVisible] = useState(false)

  const [mobileVersion, setMobileVersion] = useState(false)
  const [containerPdfViewerHeight, setContainerPdfViewerHeight] = useState('')

  useEffect(() => {
    if (context?.termsAndConditionsPresent) {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    }
  }, [])

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setContainerPdfViewerHeight(`calc(${window.innerHeight}px - 80px)`)
    }
  }, [pageWidth, pageHeight])

  const handleProceed = async () => {
    try {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    } catch (e) {
      devLog(e)
    }
  }

  const handleIdData = ({ checked: value }) => {
    setIdData(value)
    actionReport({
      type: 'event.onboarding-web.consent.OPEN_BANKING_CHECKED',
      payload: {},
    })
  }

  useEffect(() => {
    async function handleSignedConsents() {
      if (idData === true) {
        const hash = await getChecksumSha256FromFile(
          language === 'de' ? PrivacyPolicyDe : PrivacyPolicy,
          'application/pdf'
        )
        setSigned(true)
        actionReport({
          type: 'event.onboarding-web.consent.CONSENT_ACCEPTED',
          payload: {
            fileName:
              language === 'de'
                ? 'privacy_policy_de.pdf'
                : 'privacy_policy.pdf',
            fileHash: hash,
            consentText: trans.idData,
          },
        })
      } else {
        setSigned(false)
      }
    }

    handleSignedConsents()
  }, [idData])

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    } else {
      setMobileVersion(false)
    }
  }, [pageWidth])

  const isTouchScrollDisabled = () => {
    if (policyPopupVisible) {
      return 'none'
    } else {
      return 'auto'
    }
  }

  useEffect(() => {
    if (policyPopupVisible === true) {
      actionReport({
        type: 'event.onboarding-web.consent.OPEN_BANKING_DISPLAYED',
        payload: {},
      })
    }
  }, [policyPopupVisible])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
      touchAction={isTouchScrollDisabled()}
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.header}
      </Header>

      <MicropaymentInfoContent>{trans.content}</MicropaymentInfoContent>

      <MicropaymentInfoGraph>
        <DTPInfoGraph />
      </MicropaymentInfoGraph>

      <MicropaymentInfoContent>{trans.openBanking}</MicropaymentInfoContent>

      <Divider margin="30px 0" />

      <WelcomeCheck margin="40px 0 0 0">
        <DocumentCheckbox
          inputName="id-data"
          description={trans.idData}
          externalUrl={''}
          handleDecision={handleIdData}
          value={idData}
          {...theme.documentCheckbox}
          margin="0 0 30px 0"
          contentFontsize="14px"
          iconSize="24px"
          iconColor={theme.globals.checkboxColor}
          onClick={() => setPolicyPopupVisible(true)}
        />
      </WelcomeCheck>

      {mobileVersion ? <DtpGeneralConditionsSpacerMobile /> : <></>}
      <ContentContainerBottomFixed borderTop="1px solid #ced4dc">
        {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
        <Button
          id="btn-proceed"
          onClick={handleProceed}
          {...theme.button}
          disabled={!signed}
          width={mobileVersion ? '100%' : '224px'}
          paddingMobile="14px 40px"
        >
          <ButtonText>{trans.proceed}</ButtonText>
        </Button>
      </ContentContainerBottomFixed>
      {mobileVersion ? (
        <>
          <Popup
            visibility={policyPopupVisible}
            handleVisibility={setPolicyPopupVisible}
            {...theme.popup}
          >
            <PdfViewer
              file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
              {...theme.pdfViewer}
              viewerWidth="100%"
              viewerHeight={containerPdfViewerHeight}
              initialScale={1}
            />
          </Popup>
        </>
      ) : (
        <>
          <Popup
            visibility={policyPopupVisible}
            handleVisibility={setPolicyPopupVisible}
            {...theme.popup}
          >
            <PdfViewer
              file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
              {...theme.pdfViewer}
              viewerWidth="100%"
              viewerHeight={`calc(100vh - 405px)`}
              initialScale={1}
            />
          </Popup>
        </>
      )}
    </ContentContainer>
  )
})
