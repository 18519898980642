import React from 'react'

interface DownloadProps {
  color: string
  thickness?: string
}

export const Download = ({ color }: DownloadProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 3.75C10.7933 3.75 11.031 3.98776 11.031 4.28105V11.7541L13.3533 8.85127C13.5365 8.62225 13.8707 8.58511 14.0997 8.76833C14.3287 8.95155 14.3659 9.28573 14.1827 9.51475L11.5526 12.8023C11.013 13.4768 9.98701 13.4768 9.44736 12.8023L6.81735 9.51475C6.63413 9.28573 6.67126 8.95155 6.90028 8.76833C7.1293 8.58511 7.46349 8.62225 7.6467 8.85127L9.96895 11.7541L9.96895 4.28105C9.96895 3.98776 10.2067 3.75 10.5 3.75ZM4.78105 11.9199C5.07433 11.9199 5.31209 12.1577 5.31209 12.451V13.268C5.31209 14.3283 6.17168 15.1879 7.23203 15.1879H13.768C14.8283 15.1879 15.6879 14.3283 15.6879 13.268V12.451C15.6879 12.1577 15.9257 11.9199 16.219 11.9199C16.5122 11.9199 16.75 12.1577 16.75 12.451V13.268C16.75 14.9149 15.4149 16.25 13.768 16.25H7.23203C5.5851 16.25 4.25 14.9149 4.25 13.268V12.451C4.25 12.1577 4.48776 11.9199 4.78105 11.9199Z"
        fill={`${color ? color : '#280b60'}`}
      />
    </svg>
  )
}
