import {
  Header,
  LoadingSpinner,
  WrapKontomatik,
} from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { useInterval } from 'usehooks-ts'
import { StoreContext } from '../../components/App'
import { axiosInstance } from '../../methods/axiosConfig'
import { devLog } from '../../methods/devLog'
import { forceRedirect } from '../../methods/forceRedirect'
import { Margined } from '../../styles/layout.styles'

export const ProgressPageKontomatik = observer(() => {
  const store = useContext(StoreContext)
  const { kontomatikSelectedBank, shouldUploadBankStatement } = store.AppState
  const { theme } = store.InterfaceState
  const { currentPageIndex } = store.ScenarioState

  const { progressPage: trans } = store.TranslationsState.translations
  const [verified, setVerified] = useState(false)
  const [verifyFail, setVerifyFail] = useState(false)

  const handlePooling = async () => {
    try {
      const apiUrl = process.env.WEB_API_URL

      const res = await axiosInstance.get(`${apiUrl}/kontomatik/check-status`, {
        withCredentials: true,
      })

      if (res.data) {
        const { status } = res.data
        if (status === 'finished') {
          setVerified(true)
        }
      }
    } catch (e) {
      setVerifyFail(true)
      devLog(e)
    }
  }

  const handleComplete = async () => {
    try {
      if (verifyFail && shouldUploadBankStatement) {
        store.ScenarioState.setCurrentPage(currentPageIndex + 1)
      } else if (!verifyFail) {
        const apiUrl = process.env.WEB_API_URL
        forceRedirect(`${apiUrl}/kontomatik/complete`)
      }
    } catch (e) {
      devLog(e)
    }
  }

  useInterval(
    () => {
      !verified && handlePooling()
    },
    !verified ? 3000 : null
  )

  useEffect(() => {
    if (verified || verifyFail) {
      handleComplete()
    }
  }, [verified, verifyFail])

  useEffect(() => {
    if (
      kontomatikSelectedBank === 'Pko' ||
      kontomatikSelectedBank === 'Inteligo' ||
      kontomatikSelectedBank === 'PkoApi' ||
      kontomatikSelectedBank === 'pko'
    ) {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    }
  }, [])

  return (
    <WrapKontomatik
      {...theme.wrapKontomatik}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
    >
      <Margined margin="0 0 15px">
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.heading}
        </Header>
      </Margined>
      <LoadingSpinner
        width="68px"
        padding="100px 0"
        {...theme.loadingSpinner}
      />
    </WrapKontomatik>
  )
})
